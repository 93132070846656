@font-face {
  font-family: Timeless;
  src: url("Timeless.b9713fff.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: Timeless;
  src: url("Timeless-Bold.49402b5c.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}

html {
  margin: 0;
}

body {
  height: 100vh;
  height: 100dvh;
  min-height: 100vh;
  min-height: 100dvh;
  width: 100%;
  color: #7a7871;
  background: #f3f0e2;
  font-family: Timeless, sans-serif;
  font-size: .8rem;
  margin: 0 !important;
}

.h1 {
  font-family: Timeless, sans-serif;
}

.label {
  font-family: Timeless, sans-serif;
  font: "Roboto", sans-serif;
  pointer-events: none;
}

text {
  font-family: Timeless, sans-serif;
  transition: letter-spacing .5s;
}

a {
  color: #7a7871;
}

.p-20 {
  padding: 20px;
}

.pt-10 {
  padding-top: 10px;
}

.w-100 {
  width: 100%;
}

.flex-row {
  flex-direction: row;
  display: flex;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

#NW {
  width: 200px;
  z-index: 4000;
  flex-direction: row;
  align-items: center;
  font-size: 1.5rem;
  display: flex;
  position: fixed;
  top: 10px;
  left: 10px;
}

#title {
  cursor: pointer;
}

#reset_screen {
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: url("rewind.f50bb6b0.svg");
  margin-left: 5px;
}

#NE {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: fixed;
  top: 10px;
  right: 10px;
}

#NE img {
  margin-left: 5px;
}

#SW {
  width: 270px;
  max-width: 60vw;
  flex-direction: column;
  display: flex;
  position: fixed;
  bottom: 10px;
  left: 10px;
}

#SE {
  width: 150px;
  max-width: 30vw;
  flex-direction: column;
  align-items: flex-end;
  font-size: .7rem;
  display: flex;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.special-width {
  width: 320px !important;
  max-width: 320px !important;
  align-items: flex-start !important;
}

.special-width > div {
  margin-top: 4px;
}

.m-20 {
  margin: 20px;
}

#imageNodes {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

#imageNodes div {
  text-align: right;
}

.node {
  stroke: #000;
  stroke-width: 1.5px;
}

.link {
  stroke: #999;
  stroke-width: 1.5px;
}

.hidden {
  display: none !important;
}

#svg_div_div {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

#svg_div {
  width: 600px;
  height: 600px;
  max-height: min(80vh, 100vw);
  max-height: min(80dvh, 100vw);
  z-index: 1000;
  pointer-events: none;
  margin-bottom: 50px;
}

#svg_id {
  transition: transform .5s;
}

#bellyman {
  opacity: .4;
}

#control_panel_caption {
  max-width: 100%;
  width: 100%;
}

#caption {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 3px;
  overflow: hidden;
}

.swiper {
  max-width: 100vw;
  max-height: 75vh;
  max-height: 75dvh;
  transition: opacity .4s;
  position: fixed !important;
}

.center_fixed {
  top: 50%;
  transform: translateY(calc(-50% - 25px));
}

.bottom_fixed {
  bottom: 100px;
}

.swiper-wrapper {
  opacity: 0;
}

.swiper-slide {
  text-align: center;
  background: none;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  display: flex;
  width: unset !important;
}

.swiper-slide img {
  height: 70vh;
  height: 70dvh;
  max-width: 90vw;
  object-fit: contain;
  background: none;
  display: block;
}

@media (orientation: portrait), screen and (max-aspect-ratio: 1) {
  .swiper-slide img {
    height: calc(100vh + min(600px, min(80vh, 100vw)) / -2 - 100px) !important;
    height: calc(100dvh + min(600px, min(80dvh, 100vw)) / -2 - 100px) !important;
  }
}

.swiper-button-prev {
  pointer-events: none;
  margin-left: 10px;
  height: 100% !important;
  width: 50% !important;
  cursor: url("back_arrow.28c1e405.png"), auto !important;
  justify-content: flex-start !important;
  margin: 0 0 0 10px !important;
  top: 0 !important;
  left: 0 !important;
}

.swiper-button-next {
  pointer-events: none;
  height: 100% !important;
  width: 50% !important;
  cursor: url("forward_arrow.10ed7376.png"), auto !important;
  justify-content: flex-end !important;
  margin: 0 10px 0 0 !important;
  top: 0 !important;
  right: 0 !important;
}

@media (min-aspect-ratio: 0) {
  .swiper-slide img {
    width: 90vh;
    width: 90dvh;
  }
}

@media (min-aspect-ratio: 1) {
  .swiper-slide img {
    width: unset;
  }
}

.m-5 {
  margin: 5px;
}

#mc_embed_signup {
  clear: left;
  font: unset;
  background: none;
}

#mc_embed_signup form {
  padding: 0;
  margin: 0 !important;
}

.mc-field-group {
  height: 20px !important;
  min-height: 20px !important;
  flex-direction: row !important;
  align-items: center !important;
  margin: 0 !important;
  padding-bottom: 0% !important;
  display: flex !important;
}

.mc-field-group label {
  text-align: right !important;
}

#mc-label {
  width: 60px;
}

#mce-EMAIL {
  background: none;
  font-family: unset;
  height: 14px !important;
  width: 130px !important;
  border: none !important;
  border-bottom: 1px solid !important;
  border-radius: 0 !important;
  padding: 0 !important;
}

#mc_input_wrapper {
  display: inline-flex;
}

#mc-embedded-subscribe {
  width: 15px !important;
  height: 14px !important;
  color: unset !important;
  background: none !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  line-height: 14px !important;
}

#mce-error-response, #mce-success-response, #mce-EMAIL-HELPERTEXT, .mce_inline_error {
  background: none !important;
}

#mce-responses {
  color: unset !important;
  min-height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}

#mce-responses .response {
  color: unset !important;
  margin: 0 !important;
  padding: 0 !important;
}

/*# sourceMappingURL=index.15595eaa.css.map */
