/*@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400);*/

@font-face {
    font-family: 'Timeless';
    src: url('./assets/Timeless.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: 'Timeless';
    src: url('./assets/Timeless-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: bold;
}

html {
    margin: 0;
}
body {
    font-size: 0.8rem;
    margin: 0 !important;
    height:  100vh;
    height:  100dvh;
    min-height:100vh;
    min-height:  100dvh;
    width: 100%;
    background: #f3f0e2;
    color:#7A7871;
    font-family: 'Timeless', Timeless, sans-serif;
}

.h1 {
    font-family: 'Timeless', Timeless, sans-serif;
}

.label {
    font-family: 'Timeless', Timeless, sans-serif;
    font: 'Roboto', sans-serif;
    pointer-events: none;
}

text {
    font-family: 'Timeless', Timeless, sans-serif;
    transition: letter-spacing 0.5s;
    /*letter-spacing: -1.3px;*/
}

a {
    color:#7A7871;
}

.p-20 {
    padding:20px;
}

.pt-10 {
    padding-top:10px;
}
.w-100 {
    width:100%;
}
.flex-row {
    display:flex;
    flex-direction: row;
} 
.justify-content-end {
    justify-content: flex-end;
}
.align-items-center {
    align-items: center;
}

#NW {
    position: fixed;
    top: 10px;
    left:10px;
    width:200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size:1.5rem;
    z-index: 4000;
}

#title {
    cursor:pointer;
}

#reset_screen {
    cursor: pointer;
    background: url('./assets/rewind.svg');
    width: 16px;
    height: 16px;
    margin-left:5px;
/*    z-index: 4000;*/
}

#NE {
    position: fixed;
    top: 10px;
    right:10px;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;
}
#NE img {
    margin-left:5px;
}

#SW {
    position: fixed;
    bottom: 10px;
    left:10px;
    width:270px;
    max-width:60vw;
    display: flex;
    flex-direction: column;
/*    justify-content: space-between;*/
}

#SE {
    font-size:0.7rem;
    position: fixed;
    bottom: 10px;
    right:10px;
    width:150px;
    max-width:30vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.special-width {
    align-items: flex-start !important;
    width: 320px !important;
    max-width: 320px !important;
}

.special-width > div {
    margin-top:4px;
}

.m-20 {
    margin:20px;
}

#imageNodes {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

#imageNodes div {
    text-align: right;
}

.node {
    stroke: #000;
    stroke-width: 1.5px;
}

.link {
    stroke: #999;
    stroke-width: 1.5px;
}

.hidden {
    display: none !important;
}


 /*D3 SVG FORMATTING*/

#svg_div_div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#svg_div {
    width: 600px;
    height: 600px;
    max-height: min(80vh, 100vw);
    max-height: min(80dvh, 100vw);
    z-index: 1000;
    pointer-events: none;
    margin-bottom:50px;
}

#svg_id {
    transition: transform 0.5s;
}

#bellyman {
    opacity: 0.4;
}

#control_panel_caption {
    max-width:100%;
    width:100%;
}

#caption {
/*    display: flex;*/
/*    flex-direction: column;
    align-items: center;*/
    margin-top: 3px;
/*    max-width:100%;
    width:100%;*/
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}




/*SWIPER*/
.swiper {
    position: fixed !important;
    
/*    width: 100vw;*/
/*    min-width: 100vw;*/
    max-width: 100vw;
    max-height: 75vh;
    max-height:  75dvh;
    
    transition: opacity 0.4s;
/*    opacity: 0;*/
}

.center_fixed {
/*    for wide screens*/
    top: 50%; /* Position the top edge of the element at the center of the screen vertically */
    transform: translateY(calc(-50% - 25px ));
}

.bottom_fixed {
/*    for tall screens*/
    bottom: 100px;
/*    margin-top: min(40vh, 50vw);*/
}

.swiper-wrapper {
    opacity:0;
}

.swiper-slide {
  width:unset !important;
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.swiper-slide img {
  display: block;
  height: 70vh;
  height:  70dvh;
  background: transparent;
  max-width: 90vw;
  object-fit: contain;
}

/* Styles for viewport taller than wide (portrait orientation) */
@media (orientation: portrait),
screen and (max-aspect-ratio: 1/1) {
  .swiper-slide img {
    
    height: calc( 100vh - (min(600px, min(80vh, 100vw)) / 2 ) - 100px) !important;
    height: calc(  100dvh - (min(600px, min(80dvh, 100vw)) / 2 ) - 100px) !important;

  }
}

.swiper-button-prev {
    height:100% !important;
    margin:0px 0px 0px 10px !important;
    top:0px !important;
    left:0px !important;
    width:50% !important;
    justify-content: flex-start !important;
    margin-left:10px;
    cursor:url("./assets/back_arrow.png"), auto !important;
    pointer-events: none;
}

.swiper-button-next {
    height:100% !important;
    margin:0px 10px 0 0 !important;
    top:0px !important;
    right:0px !important;
    width:50% !important;
    justify-content: flex-end !important;
    cursor: url("./assets/forward_arrow.png"), auto !important;
    pointer-events: none;
}

@media (min-aspect-ratio:0) {
    .swiper-slide img {
        width:90vh;
        width: 90dvh;
    }
}

@media (min-aspect-ratio:1) {
    .swiper-slide img {
        width:unset;
    }
}

.m-5 {
    margin:5px;
}

/*mailchimp*/

#mc_embed_signup{
    background:transparent;
    clear:left;
    font:unset;
}

#mc_embed_signup form {
    margin: 0px !important;
    padding:0px;
}

.mc-field-group {
    display:flex !important;
    flex-direction: row !important;
    align-items: center !important;
    margin: 0px !important;
    height:20px !important;
    min-height:20px !important;
    padding-bottom:0% !important;
}

.mc-field-group label {
    text-align:right !important;
}
#mc-label {
    width:60px;
}

#mce-EMAIL {
    height: 14px !important;
    padding:0px !important;
    width:130px !important;
    border: none !important;
    background:transparent;
    font-family: unset;
    border-radius: 0px !important;
    border-bottom:1px solid !important;
}

#mc_input_wrapper {
    display:inline-flex;
    
}

#mc-embedded-subscribe {
    width:15px !important;
    height:14px !important;
    background:transparent !important;
    color:unset !important;
    margin: 0px !important;
    line-height:14px !important;
    padding:0px !important;
    border:0px !important;
}

#mce-error-response,
#mce-success-response,
#mce-EMAIL-HELPERTEXT,
.mce_inline_error {
    background: transparent !important;
}

#mce-responses {
    padding:0px !important;
    margin:0px !important;
    color:unset !important;
    min-height:0px !important;
}

#mce-responses .response {
    padding:0px !important;
    margin:0px !important;
    color:unset !important;
}